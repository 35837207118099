import React, { useState } from 'react';
import Swal from 'sweetalert2'; // Import SweetAlert2 for alerts

const ContactForm = () => {
  const [result, setResult] = useState(""); // Track the result message

  // Handle form submission
  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending...");

    const formData = new FormData(event.target);
    formData.append("access_key", "86f152cd-d288-4a5f-9a46-0da8d7b65637"); // Use your Web3Forms access key

    try {
      const response = await fetch("https://api.web3forms.com/submit", { // Use Web3Forms endpoint
        method: "POST",
        body: formData // Send FormData directly
      });

      const jsonResponse = await response.json(); // Parse the JSON response

      if (jsonResponse.success) {
        setResult("Form Submitted Successfully");
        event.target.reset(); // Reset form after submission

        // Trigger a SweetAlert success notification
        Swal.fire({
          title: "Success!",
          text: jsonResponse.message || "Your message was sent successfully.",
          icon: "success",
          confirmButtonText: 'OK'
        });
      } else {
        setResult(jsonResponse.message || "Something went wrong. Please try again later.");

        // Trigger a SweetAlert error notification
        Swal.fire({
          title: "Error!",
          text: jsonResponse.message || "Something went wrong. Please try again later.",
          icon: "error",
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      console.error("Error during form submission: ", error);
      setResult("There was an error sending your message.");

      // Trigger a SweetAlert error notification for network or server errors
      Swal.fire({
        title: "Error!",
        text: "There was an error sending your message. Please check your internet connection and try again.",
        icon: "error",
        confirmButtonText: 'OK'
      });
    }
  };

  // Inline styles for the form
  const formStyle = {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    border: '1px solid #ccc',
    borderRadius: '15px',
    fontSize: '16px',
    boxSizing: 'border-box'
  };

  const textareaStyle = {
    ...inputStyle, // Use the same styles as input, but add more rows
    resize: 'vertical',
    height: '100px'
  };

  const footerTextStyle = {
    marginTop: '10px',
    fontSize: '24px',
    color: '#666',
    textAlign: 'center',
  };

  const Header = {
    color: '#4A586E',
    textAlign: 'center',
    padding: '10px 40px 30px',
  }

  const SendBtn = {
    backgroundColor: '#383780', // Use camelCase
    color: 'white',
    padding: '12px 20px',
    marginBottom: '20px',
    borderRadius: '30px', // Use camelCase
    border: 'none',
    fontSize: '1rem', // Use camelCase
    fontWeight: '500', // Use camelCase
    cursor: 'pointer',
    transition: 'background-color 0.3s ease', // Use camelCase
  };

  return (
    <div id="contact" className="contact" style={formStyle}>
      <h2 style={Header}>Bulk SMS Registration Form</h2>
      <form onSubmit={onSubmit}>
        <input
          type="text"
          name="name"
          style={inputStyle}
          placeholder="Full Name"
          required
        />
        <input
          type="text"
          name="company_name"
          style={inputStyle}
          placeholder="Company Name"
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          style={inputStyle}
          required
        />
        <input
          type="tel"
          name="phone"
          placeholder="Phone Number"
          style={inputStyle}
          required
        />
        <input
          type="text"
          name="country"
          placeholder="Country"
          style={inputStyle}
          required
        />
        <input
          type="number"
          name="expected_volume"
          placeholder="Expected Volume per Month"
          style={inputStyle}
          required
        />
        <input
          type="text"
          name="purpose_of_sender"
          placeholder="Purpose of Sender ID"
          style={inputStyle}
          required
        />
        <input
          type="text"
          name="address"
          placeholder="Physical Address"
          style={inputStyle}
          required
        />
        <input
          type="date"
          name="date_of_registration"
          placeholder="Date of Registration"
          style={inputStyle}
          required
        />
        <input
          type="text"
          name="type_of_business"
          placeholder="Type of Business"
          style={inputStyle}
          required
        />
        <input
          type="text"
          name="preferred_sender_id"
          placeholder="Preferred Sender ID"
          style={inputStyle}
          required
        />

        {/* Updated Textarea for Sample Text */}
        <textarea
          name="sample_text"
          placeholder="Sample Text"
          style={textareaStyle}
          required
        ></textarea>

<p style={footerTextStyle} >
        Please ensure all details are accurate before submission.
      </p><br></br>


<button type="submit" style={SendBtn}>SEND</button>
</form>

     
      <span>{result}</span> {/* Display result message */}
    </div>
  );
};

export default ContactForm;
