import React, { useState, useEffect, useRef } from 'react';
import '../styles/Navbar.css'; // Ensure the correct path to your CSS file
import Logo from '../../src/assets/logo2.png'; // Adjust the path to your logo

const Navbar = () => {
  const [isNavOpen, setNavOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleNavToggle = () => {
    setNavOpen(prev => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setNavOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setNavOpen(false); // Close nav on escape key
    }
  };

  const scrollToSection = (e, sectionId) => {
    e.preventDefault(); // Prevent default jump behavior
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: 'smooth', // Smooth scroll
        block: 'start', // Scroll to the start of the section
      });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scroll to the top
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <nav id="home" className="navbar">
      <div className="logo" onClick={scrollToTop}> {/* Scroll to top when logo is clicked */}
        <img src={Logo} alt="Tatima Marketing" />
      </div>
      <div 
          className={`hamburger ${isNavOpen ? 'open' : ''}`} 
          onClick={handleNavToggle} 
          aria-label="Toggle navigation"
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <ul className={`nav-links ${isNavOpen ? 'open' : ''}`}>
        <li><a href="#home" onClick={scrollToTop}>Home</a></li> {/* Scroll to top when Home is clicked */}
        <li><a href="#about" onClick={(e) => scrollToSection(e, '#about')}>About</a></li>
        <li><a href="#services" onClick={(e) => scrollToSection(e, '#services')}>Services</a></li>
        <li><a href="#whychooseus" onClick={(e) => scrollToSection(e, '#whychooseus')}>Why Choose Us?</a></li>
        <li><a href="https://wa.me/+26772546157?text=Hello%20I'm%20inquiring%20about%20the%20BULK SMS" target='blank'>Contact</a></li>
      </ul>
    </nav>
  );
};

export default Navbar;
