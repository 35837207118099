import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  // Import FontAwesomeIcon
import { faInstagram, faTwitter, faYoutube, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';  // Import specific icons
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Contact Info Section */}
        <div className="footer-section">
          <h3>Contact Info</h3>
          <p>Zim: +263 772 528782</p>
          <p>Bots: +267 72 546 157</p>
          <p>Harare, Zimbabwe</p>
          <p>Gaborone, Botswana</p>
          <p>admin@tatimamarketing.com</p>
        </div>

              {/* Follow Us Section with Links */}
              <div className="footer-section">
          <h3>Follow Us</h3>
          <div className="footer-social-icons">
            <p>
              <a href="https://www.instagram.com/tatimamarketing?igsh=MWYxNmhzOWh1cXFpYQ%3D%3D&utm_source=qr
" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} /> Instagram
              </a>
            </p>
          
            <p>
              <a href="https://www.facebook.com/profile.php?id=61566959040938&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} /> Facebook
              </a>
            </p>
            <p>
              <a href="https://www.facebook.com/profile.php?id=61566959040938&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
              </a>
            </p>
               <p>
              <a href="https://www.twitter.com/yourprofile" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} /> Twitter
              </a>
            </p>
            <p>
              <a href="https://www.youtube.com/yourprofile" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} /> YouTube
              </a>
            </p> 
          </div>
        </div>

        {/* Downloads Section */}
        <div className="footer-section">
          <h3>Downloads</h3>
          <p>
            <a href="/assets/regform.pdf" download="regform.pdf">Registration Form (PDF)</a>
          </p>
          <p>
            <a href="/assets/pricebundlesusd.png" download="pricebundlesusd.png">Price Bundles USD</a>
          </p>
          <p>
            <a href="/assets/pricebundlesbwp.png" download="pricebundlesbwp.png">Price Bundles BWP</a>
          </p>
          <p>
            <a href="/assets/webzim.png" download="webzim.png">Web Development Prices - Zimbabwe</a>
          </p>
          <p>
            <a href="/assets/prices.jpeg" download="prices.jpeg">Web Development Prices - Botswana</a>
          </p>
        </div>
      </div>

      {/* Footer Copyright Section */}
      <div className="footer-section2">
        <p>&copy; 2024 Tatima Marketing All rights reserved.</p>
        <p>Author: Sir Arnold Trevor</p>
      </div>
    </footer>
  );
};

export default Footer;
