import React from 'react';
import '../styles/HeroSection.css';
import ContactForm from '../components/ContactForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeadphonesSimple,
  faCode,
  faUserSecret,
  faComments,
  faRobot,
  faEarth,
  faChartArea,
} from '@fortawesome/free-solid-svg-icons';


const HeroSection = () => {
  const handleScroll = () => {
    const element = document.getElementById('contact'); // Target ID
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>EXPAND YOUR BUSINESS REACH</h1>
          <button className="cta-button" onClick={handleScroll}>
            Register Here →
          </button>
        </div>
      </section>

      {/* Info Section: Two Columns */}
      <section className="info-section">
        <div className="info-container">
          <div className="column column1">
            <div className="video-wrapper">
            <video  
                width="100%" 
                height="600px" 
                controls 
                autoPlay 
                muted 
                loop>
                <source src={`${process.env.PUBLIC_URL}/assets/tatima.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div id="about" className="column column2">
            <h1>ABOUT US</h1>


{/* Add the horizontal line here */}
<hr style={{ border: '1px solid #ccc', width: '100%', margin: '40px auto' }} />

            <h3>Our Commitment to Excellence</h3>
            <p className="justified-text">
              Tatima Marketing is committed to delivering exceptional service and results to our clients. We pride ourselves on our:
              <br /><br />
              • Innovative Approach: We continuously invest in research and development to ensure our solutions stay ahead of industry trends and meet the evolving needs of our clients.
              <br />• Reliability: With a proven track record of successful campaigns and a 99.9% uptime guarantee, our clients can trust that their messages will be delivered reliably.
              <br />• Customer Focus: We value our clients and strive to build long-term partnerships based on trust, transparency, and mutual success.
            </p>
            <h3 >Our Mission</h3>
            
            <p className="justified-text">
              Tatima Marketing aims to empower businesses of all sizes by leveraging mobile communication. <br />
              We provide innovative bulk SMS solutions that enable clients to effectively reach their target audience, foster stronger relationships, and achieve their marketing objectives.
            </p>
          
         
            
          </div>
        </div>
      </section>

      {/* Services Section: Top Column + Three Cards */}
      <section className="cards-section">
        <div className="column-container">
          {/* Top Column */}
          <div id="services" className="top-column">
          <h1>OUR SERVICES</h1>

{/* Add the horizontal line here */}
<hr style={{ border: '1px solid #ccc', width: '100%', margin: '40px auto' }} />

          </div>

          {/* Three Cards */}
          <div className="cards-container">
        
            <div className="card">
              <FontAwesomeIcon icon={faComments} size="2x" style={{ color: '#383780' }} fade />
              <h3>Bulk SMS</h3>
              <p> Send instant messages to your customers with our easy-to-use Bulk SMS platform. <br></br>
              
              <button className="cta-button1" onClick={handleScroll}>
      Register →
    </button>

  </p>
            </div>

            <div className="card">
              <FontAwesomeIcon icon={faCode} size="2x" style={{ color: '#383780' }} fade />
              <h3>Web Development</h3>
              <p>We create responsive, user-friendly websites tailored to your business.</p>
            </div>

            <div className="card">
              <FontAwesomeIcon icon={faUserSecret} size="2x" style={{ color: '#383780' }} fade />
              <h3>Cooperate Wear & Gifts</h3>
              <p>Supply your team or clients with custom-branded corporate wear and gifts.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Additional Services Section: Six Cards */}
      <section className="cards-section1">
      
        <div className="cards-row">
          <div className="card1">
            <FontAwesomeIcon icon={faChartArea} size="2x" style={{ color: '#A9ABAE', padding: 20 }} beat />
            <div className="card1-text">
              <h3>Detailed Analytics</h3>
              <p >
              
Gain valuable insights with our detailed analytics, tracking the performance of your campaigns in real-time to help optimize strategies and improve results.
              </p>
            </div>
          </div>

          <div className="card1">
            <FontAwesomeIcon icon={faHeadphonesSimple} size="2x" style={{ color: '#A9ABAE', padding: 20 }} beat />
            <div className="card1-text">
              <h3>Dedicated Support</h3>
              <p >
              
Our team is here to assist you every step of the way, offering personalized support to ensure your success and smooth operat
              </p>
            </div>
          </div>

      
        </div>

        <div className="cards-row">
     
        <div className="card1">
            <FontAwesomeIcon icon={faRobot} size="2x" style={{ color: '#A9ABAE', padding: 20 }} beat />
            <div className="card1-text">
              <h3>Automated Messaging</h3>
              <p >
              Streamline your communication with automated messaging solutions for reminders, notifications, and customer engagement, ensuring timely and efficient interactions with your audience.
              </p>
            </div>
          </div>
    

          <div className="card1">
            <FontAwesomeIcon icon={faEarth} size="2x" style={{ color: '#A9ABAE', padding: 20 }} beat />
            <div className="card1-text">
              <h3>Global Reach</h3>
              <p >
              Expand your business’s influence with our services, enabling you to connect with audiences worldwide quickly and effectively.
              </p>
            </div>
          </div>
        </div>
      </section>

  {/* Services Section: Top Column + Three Cards */}
  <section className="cards-section2">
  <div className="column-container">
    {/* Title */}
    <div id="whychooseus" className="top-column2">
      <h1>WHY CHOOSE US?</h1>

{/* Add the horizontal line here */}
<hr style={{ border: '1px solid #ccc', width: '100%', margin: '40px auto' }} />

      <h2>At Tatima Marketing, we pride ourselves on delivering exceptional value through our three major services: Corporate Wear and Gifts, Web Development Services, and Digital Marketing Services.<br></br> Here’s why you should choose us:</h2>
    </div>

    {/* Three Columns */}
    <div className="content-container">
      <div className="column">
        <h3>Quality Corporate Wear & Gifts:</h3>
        <ul>
          <li>Our corporate wear is designed to enhance your brand's image with unique, high-quality products that reflect professionalism and style. </li>
          <li>Whether you need uniforms, promotional items, or personalized gifts, we ensure that our offerings meet the highest standards.</li>
        </ul>
      </div>

      <div className="column">
        <h3>Expert Web Development Services:</h3>
        <ul>
          <li>We create user-friendly, visually appealing websites tailored to your business needs.</li>
          <li>Our team of skilled developers employs the latest technologies to ensure your online presence is not only attractive but also functional and optimized for performance.</li>
        </ul>
      </div>

      <div className="column">
        <h3>Effective Digital Marketing Solutions:</h3>
        <ul>
          <li>Our digital marketing services are designed to elevate your brand visibility and engagement. </li> 
           <li>From targeted social media campaigns to impactful bulk SMS marketing, we help you reach your audience effectively and drive conversions.</li>
        </ul>
      </div>
      <h2>By choosing Tatima Marketing, you benefit from our commitment to quality, innovation, and customer satisfaction. Let us help you achieve your business goals with our comprehensive solutions tailored just for you!</h2>
    </div>
  </div>
</section>





      {/* Contact Form Section */}
      <section className="form-section, cards-section3">
        <ContactForm />
      </section>
    </>
  );
};

export default HeroSection;
